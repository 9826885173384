.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.display-flex {
    display: flex;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.display-table-cell {
    display: table-cell;
}

.align-self-center {
    align-self: center;
}

.float-right {
    float: right;
}

.center-text {
    text-align: center;
}

.width-fit-content {
    width: fit-content;
}

.height-fit-content {
    height: fit-content;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-no-drop {
    cursor: no-drop;
}

* {
    outline: none;
}

button {
    cursor: pointer;
}

.vertical-align-middle {
    vertical-align: middle;
}

.goBack {
    color: #000000;
    cursor: pointer;
}

hr {
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 0;
    padding: 0;
}


/* react collapse animation */

.ReactCollapse--collapse {
    transition: height 500ms;
}

.resp__so {
    display: none;
}

.resp__bo {
    display: block;
}

@media only screen and (max-width: 768px) {
    .resp__so {
        display: block;
    }
    .resp__bo {
        display: none;
    }
}